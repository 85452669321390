.create-ques-wrapper {
  min-height: 100vh;
  padding: 2rem 0;
  padding-bottom: 1rem;

  button {
    padding: 1rem;
    svg {
      path {
        stroke: #fff;
      }
    }
  }

  .create-form {
    gap: 1rem;
    margin: auto;
    display: flex;
    max-width: 85vw;
    flex-direction: column;

    .form {
      display: flex;
      gap: 1.5rem;

      .form-control {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        label {
          font-weight: bold;
        }
        textarea {
          font-size: 1.1rem;
          resize: none;
          padding: 0.5rem;
          border: 1px solid #d9d9d9;
          &:focus {
            outline: none;
          }
        }
      }

      .form-left {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;

        .grp-form {
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          select,
          input {
            font-size: 1.1rem;
            border-radius: 5px;
            border: 1px solid #d9d9d9;
            padding: 0.8rem 0.6rem;
            color: rgba(51, 51, 51, 0.699);
            background: #fff;
            &:focus {
              outline: none;
            }
          }
          .search-box {
            .search {
              border-radius: 5px;
              overflow: hidden;
              display: flex;
              input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
              div {
                padding: 0 0.5rem;
                background: #d9d9d9;
                display: grid;
                place-items: center;
                svg {
                  fill: #fff;
                }
              }
            }
          }
        }
        .ques {
          .ques-dv {
            display: flex;
            justify-content: space-between;
            .lmt-life {
              display: flex;
              gap: 0.5rem;
              align-items: center;
            }
          }
        }
        .image-box {
          input {
            padding: 0.8rem 0.6rem;
          }
          .img-upl {
            .choose-file {
              cursor: pointer;
              padding: 0.6rem 0.8rem;
              width: 100%;
              color: #fff;
              border: 1px solid #276fce;
              gap: 0.3rem;
              background: #276fce;
              span {
                margin-left: 0.5rem;
              }
              transition: all 0.3s linear;
              #file {
                display: none;
              }
              svg {
                path {
                  stroke: #fff !important;
                  transition: all 0.3s linear;
                }
              }
              &:hover {
                background: #fff;
                color: #333;
                svg {
                  path {
                    stroke: #333 !important;
                  }
                }
              }
            }
          }
        }
      }

      .form-right {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        select {
          border: 1px solid #d9d9d9;
          padding: 0.8rem 0.6rem;
          font-size: 1.1rem;
          color: rgba(51, 51, 51, 0.699);
          background: #fff;
          &:focus {
            outline: none;
          }
        }

        .options {
          .options-cont {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .option {
              border: 1px solid #d9d9d9;
              display: flex;
              gap: 0.5rem;
              align-items: center;
              padding: 0 0.5rem 0 1rem;
              justify-content: space-between;

              input {
                font-size: 1rem;
                border: 1px solid;
                padding: 0.6rem;
                border: none;
                padding-left: 0;
                flex-grow: 1;
              }

              .opt-btn {
                display: flex;
                gap: 0.6rem;
              }

              svg {
                cursor: pointer;
                width: 35px !important;
              }
            }
          }
        }
        .one-word-wrappper {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .form-control {
            input {
              padding: 0.8rem 0.6rem;
              font-size: 1.1rem;
              &:nth-child(2) {
                border: 1px solid #8cc295;
                color: #8cc295;
                &::placeholder {
                  color: #8cc295;
                }
              }
            }
          }
        }
        .show {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .create-ques-wrapper {
    .create-form {
      .form {
        flex-direction: column;
        .form-left {
          width: 100%;
        }
        .form-right {
          width: 100%;
        }
      }
    }
  }
}
