.review-ques-wrapper{
    
    display: flex;
    flex-direction: column;
   
    .no-quiz{
        position: absolute;
        top: 50%;
        left: 50%;
        color: #333;
        transform: translate(-50%,-50%);
    }
    .review-box{
        margin: auto;
        width: 85vw;
        display: flex;
        gap: 1rem;
        .review-left{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 50%;
            .search{
                cursor: pointer;
                display: flex;
                width: fit-content;
                border-radius: 5px;
                overflow: hidden;
                input{
                    padding: 0.8rem 0.7rem;
                    background: #fff;
                }
                div{
                    display: grid;
                    place-items: center;
                    padding: 0 0.5rem;
                    background: #d9d9d9;
                    svg{
                        fill: #fff;
                    }
                }
            }
            .ques-box{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                h4{color: #004AAD;}
                .ques{font-weight: bold;}
                .ques-img{
                    max-width: 18rem;
                    img{
                        width: 100%;
                    }
                }
                .ques-remarks{
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    font-weight:600;
                    color: #b6b6b6;
                }
            }
        }
        .review-right{
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .btn-div{
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                align-items: center;
                .edit , .trash{
                    cursor: pointer;
                    svg{width: 40px !important;}
                }
            }
            .option-cont{
                display: flex;
                flex-direction: column;
                label{
                    color: #004AAD;
                    margin: 0.5rem 0;
                    font-weight: bold;
                }
                .options{
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    .option{
                        padding: 1rem;
                        border: 1px solid #d9d9d9;
                    }
                }
            }
        }
    }
    .footer{
        margin-top: 2rem;
       border-top: 1px solid #d9d9d9;
        display: flex;
        justify-content: space-evenly;
        padding: 1.3rem;
        gap: 2rem;
        flex-wrap: wrap;
    }
}

@media screen and (max-width:786px){
    .review-ques-wrapper{
        .review-box{
            flex-direction: column;
            .review-left,.review-right{width: 100%;}
        }
    }
}