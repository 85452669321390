.login-wrapper{
    min-height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header , .login-footer{
        width: 100%;
        background: #004AAD;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0.5rem 0;
    }
    .login{
       .or-line{
        position: relative;
        margin-top: 1.5rem;
        margin-bottom: 0;
        span{
            position: absolute;
            left: 50%;
            top: 0%;
            padding: 0 0.8rem;
            background: #fff;
            transform: translate(-50%,-50%);
        }
       }
        padding: 1rem 0.8rem 3rem 0.8rem;
        .login-options{
            display: flex;
            flex-direction: column;
            text-align: center;
            button{
                &:hover:nth-child(2){
                    background:rgb(16, 170, 16) !important;
                }
            }
        }
        .login-form{
            .css-nzinn5-MuiContainer-root{
                padding: 0;
            }
            .MuiBox-root{
                padding: 0;
                margin-top: 1rem;
            }
        }
    }
    .sign-up{
        display: flex;
        justify-content: space-between;
    }
    .login-footer{
        padding: 0rem;
        flex-direction: row;
        color: #fff;
        gap: 1rem;
        img{
            width: 60px;
            height: 15px;
        }
    }
}