.review-ques-wrapper {
  
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.review-ques-wrapper .no-quiz {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #333;
  transform: translate(-50%, -50%);
}
.review-ques-wrapper .review-box {
  margin: auto;
  width: 85vw;
  display: flex;
  gap: 4rem;
}
.review-ques-wrapper .review-box .review-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}
.review-ques-wrapper .review-box .review-left .search {
  cursor: pointer;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
}
.review-ques-wrapper .review-box .review-left .search input {
  padding: 0.8rem 0.7rem;
  background: #fff;
}
.review-ques-wrapper .review-box .review-left .search div {
  display: grid;
  place-items: center;
  padding: 0 0.5rem;
  background: #d9d9d9;
}
/* .review-ques-wrapper .review-box .review-left .search div svg {
  fill: #fff;
} */
.review-ques-wrapper .review-box .review-left .ques-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.review-ques-wrapper .review-box .review-left .ques-box h4 {
  color: #004aad;
}
.review-ques-wrapper .review-box .review-left .ques-box .ques {
  font-weight: bold;
}
.review-ques-wrapper .review-box .review-left .ques-box .ques-img {
  max-width: 25rem;
}
.review-ques-wrapper .review-box .review-left .ques-box .ques-img img {
  width: 100%;
}
.review-ques-wrapper .review-box .review-left .ques-box .ques-remarks {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-weight: 600;
  color: #b6b6b6;
}
.review-ques-wrapper .review-box .review-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.review-ques-wrapper .review-box .review-right .btn-div {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}
.review-ques-wrapper .review-box .review-right .btn-div .edit,
.review-ques-wrapper .review-box .review-right .btn-div .trash {
  cursor: pointer;
}
.review-ques-wrapper .review-box .review-right .btn-div .edit svg,
.review-ques-wrapper .review-box .review-right .btn-div .trash svg {
  width: 40px !important;
}
.review-ques-wrapper .review-box .review-right .option-cont {
  display: flex;
  flex-direction: column;
}
.review-ques-wrapper .review-box .review-right .option-cont label {
  color: #004aad;
  margin: 0.5rem 0;
  font-weight: bold;
}
.review-ques-wrapper .review-box .review-right .option-cont .options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.review-ques-wrapper .review-box .review-right .option-cont .options .option {
  padding: 1rem;
  border: 1px solid #d9d9d9;
}
.review-ques-wrapper .footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-evenly;
  padding: 1.3rem;
  gap: 2rem;
  flex-wrap: wrap;
}
.review-ques-wrapper .footer2 {
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-evenly;
  padding: 1.3rem;
  gap: 2rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 786px) {
  .review-ques-wrapper .review-box {
    flex-direction: column;
  }
  .review-ques-wrapper .review-box .review-left,
  .review-ques-wrapper .review-box .review-right {
    width: 100%;
  }
} /*# sourceMappingURL=curator_review.css.map */
