.dashboard-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(51, 51, 51, 0.37);
  padding: 0.5rem 4rem;
  align-items: center;
}
.dashboard-nav .profile {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.dashboard-nav .profile .user-img {
  margin-left: 0.5rem;
  width: 35px;
  border-radius: 50%;
}
.dashboard-nav .profile .profile-cont {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  top: 120%;
  padding: 0.8rem 0;
  border-radius: 10px;
  width: 9rem;
}
.dashboard-nav .profile .profile-cont p, .dashboard-nav .profile .profile-cont .log-out {
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.dashboard-nav .profile .profile-cont p:hover, .dashboard-nav .profile .profile-cont .log-out:hover {
  background: #eee;
}
.dashboard-nav .profile .profile-cont .log-out {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}/*# sourceMappingURL=navbar.css.map */