.create-ques-wrapper {
  min-height: 100vh;
  padding: 2rem 0;
  padding-bottom: 1rem;
}
.create-ques-wrapper button {
  /* padding: 1rem; */
}
.create-ques-wrapper button svg path {
  stroke: #fff;
}
.create-ques-wrapper .create-form {
  gap: 1rem;
  margin: auto;
  display: flex;
  max-width: 85vw;
  flex-direction: column;
}
.create-ques-wrapper .create-form .form {
  display: flex;
  gap: 1.5rem;
}
.create-ques-wrapper .create-form .form .form-control {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.create-ques-wrapper .create-form .form .form-control label {
  font-weight: bold;
}
.create-ques-wrapper .create-form .form .form-control textarea {
  font-size: 1.1rem;
  resize: none;
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
}
.create-ques-wrapper .create-form .form .form-control textarea:focus {
  outline: none;
}
.create-ques-wrapper .create-form .form .form-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}
.create-ques-wrapper .create-form .form .form-left .grp-form {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.create-ques-wrapper .create-form .form .form-left .grp-form select,
.create-ques-wrapper .create-form .form .form-left .grp-form input {
  font-size: 1.1rem;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 0.8rem 0.6rem;
  color: rgba(51, 51, 51, 0.699);
  background: #fff;
}
.create-ques-wrapper .create-form .form .form-left .grp-form select:focus,
.create-ques-wrapper .create-form .form .form-left .grp-form input:focus {
  outline: none;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .grp-form
  .search-box
  .search {
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .grp-form
  .search-box
  .search
  input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .grp-form
  .search-box
  .search
  div {
  padding: 0 0.5rem;
  background: #d9d9d9;
  display: grid;
  place-items: center;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .grp-form
  .search-box
  .search
  div
  svg {
  fill: #fff;
}
.create-ques-wrapper .create-form .form .form-left .ques .ques-dv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-ques-wrapper .create-form .form .form-left .ques .ques-dv .lmt-life {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.create-ques-wrapper .create-form .form .form-left .image-box input {
  padding: 0.8rem 0.6rem;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .image-box
  .img-upl
  .choose-file {
  cursor: pointer;
  padding: 0.6rem 0.8rem;
  width: 100%;
  color: #fff;
  border: 1px solid #276fce;
  gap: 0.3rem;
  background: #276fce;
  transition: all 0.3s linear;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .image-box
  .img-upl
  .choose-file
  span {
  margin-left: 0.5rem;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .image-box
  .img-upl
  .choose-file
  #file {
  display: none;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .image-box
  .img-upl
  .choose-file
  svg
  path {
  stroke: #fff !important;
  transition: all 0.3s linear;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .image-box
  .img-upl
  .choose-file:hover {
  background: #fff;
  color: #333;
}
.create-ques-wrapper
  .create-form
  .form
  .form-left
  .image-box
  .img-upl
  .choose-file:hover
  svg
  path {
  stroke: #333 !important;
}
.create-ques-wrapper .create-form .form .form-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.create-ques-wrapper .create-form .form .form-right select {
  border: 1px solid #d9d9d9;
  padding: 0.8rem 0.6rem;
  font-size: 1.1rem;
  color: rgba(51, 51, 51, 0.699);
  background: #fff;
}
.create-ques-wrapper .create-form .form .form-right select:focus {
  outline: none;
}
.create-ques-wrapper .create-form .form .form-right .options .options-cont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .options
  .options-cont
  .option {
  /* border: 1px solid #d9d9d9; */
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0 0rem 0 1rem;
  justify-content: space-between;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .options
  .options-cont
  .option
  input {
  font-size: 1rem;
  border: 1px solid;
  padding: 0.6rem;
  border: none;
  padding-left: 0;
  flex-grow: 1;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .options
  .options-cont
  .option
  .opt-btn {
  display: flex;
  gap: 0.6rem;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .options
  .options-cont
  .option
  svg {
  cursor: pointer;
  width: 35px !important;
}
.create-ques-wrapper .create-form .form .form-right .one-word-wrappper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .one-word-wrappper
  .form-control
  input {
  padding: 0.8rem 0.6rem;
  font-size: 1.1rem;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .one-word-wrappper
  .form-control
  input:nth-child(2) {
  border: 1px solid #8cc295;
  color: #8cc295;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .one-word-wrappper
  .form-control
  input:nth-child(2)::-moz-placeholder {
  color: #8cc295;
}
.create-ques-wrapper
  .create-form
  .form
  .form-right
  .one-word-wrappper
  .form-control
  input:nth-child(2)::placeholder {
  color: #8cc295;
}
.create-ques-wrapper .create-form .form .form-right .show {
  display: none;
}

@media screen and (max-width: 786px) {
  .create-ques-wrapper .create-form .form {
    flex-direction: column;
  }
  .create-ques-wrapper .create-form .form .form-left {
    width: 100%;
  }
  .create-ques-wrapper .create-form .form .form-right {
    width: 100%;
  }
} /*# sourceMappingURL=createQues.css.map */
