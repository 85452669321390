.login-wrapper {
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.login-wrapper .header{
  width: 100%;
  background: #004AAD;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem 0;
}
.login-wrapper .login {
  padding: 1rem 0.8rem 3rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.login-options{
  margin:0 auto;
  
}
/*# sourceMappingURL=login.css.map */