.curator-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem 0;
    max-width: 85vw;
    margin: auto;
    width: 100%;
    .curator-info{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 1.5rem;
        h2{
            font-size: 3rem;
        }
    }
    .create{
        cursor: pointer;
        background: #004AAD;
        color: #fff;
        padding: 0.7rem 0;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span{
            font-size: 1.2rem;
            &:nth-child(1){
                width: 50%;
            }
            &:nth-child(2){
                padding: 1rem;
                background: #fff;
                border-radius: 9px;
            }
        }
    }
    .curator-options{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 3rem;
        flex-wrap: wrap;
        .opt-list{
            flex-grow: 1;
            cursor: pointer;
            align-items: center;
            background: #eee;
            padding: 1rem;
            border-radius: 10px;
            display: flex;
            justify-content: space-around;
            span{
                &:nth-child(2){
                    border: 1px solid #333;
                    border-radius: 10px;
                    padding: 1rem;
                }
            }
        }
    }

}