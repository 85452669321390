.curator-stats-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 85vw;
  margin: auto;
  margin-top: 2rem;
}
.curator-stats-wrapper .date-view {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.curator-stats-wrapper .date-view .date {
  display: flex;
  gap: 0.5rem;
}
.curator-stats-wrapper .date-view button {
  background: #fff;
  border: 1px solid #004aad;
  border-radius: 5px;
  color: #004aad;
  padding: 0.3rem 2rem;
  transition: all 0.3s linear;
  cursor: pointer;
}
.curator-stats-wrapper .date-view button:hover {
  background: #004aad;
  color: #fff;
}
.curator-stats-wrapper .card-wrapper {
  display: flex;
}
.curator-stats-wrapper .card-wrapper .card1-box {
 
  display: flex;
  justify-content: space-evenly;
  margin:auto;
 
}
.curator-stats-wrapper .card-wrapper .card1-box .card1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin: 0rem 1rem;
  padding:0.5rem 2rem;
}
.curator-stats-wrapper .card-wrapper .card1-box .card1 h2 {
  font-size: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


/*# sourceMappingURL=curatorstats.css.map */