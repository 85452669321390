.curator-stats-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 85vw;
    margin: auto;
    margin-top: 2rem;
    .date-view{
        display: flex;
        align-items: center;
        gap: 1rem;
        .date{
            display: flex;
            gap: 0.5rem;
        }
        button{
            background: #fff;
            border: 1px solid #004aad;
            border-radius: 5px;
            color: #004aad;
            padding: 0.3rem 2rem;
            transition: all 0.3s linear;
            cursor: pointer;
            &:hover{
                background: #004aad;
                color: #fff;
            }
        }
    }
    .card-wrapper{
        display: flex;
        .card1-box{
            width: 50%;
            display: flex;
            justify-content: space-evenly;
            .card1{
                display: flex;
                flex-direction:column;
                justify-content: space-around;
                border: 1px solid #d9d9d9;
                border-radius: 20px;
                padding: 0 1rem;
                h2{font-size: 3.5rem;}
            }
        }
        .card2-box{
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 1rem;
            .card2{
                gap: 1rem;
                display: flex;
                padding: 1rem;
                flex-direction: column;
                justify-content: space-around;
                border-radius: 20px;
                border: 1px solid #d9d9d9;
                width: 13rem;
            }
        }
    }

}