*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a{
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.app{
  min-height: 100vh;
}
body{
  font-family: 'Roboto', sans-serif;
}
textarea::placeholder{
  font-family: 'Roboto', sans-serif;
}
input{
  border: 1px solid #D9D9D9;
}
input:focus{
  outline: none;
}
