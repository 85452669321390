.curator-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem 0;
  max-width: 85vw;
  margin: auto;
  width: 100%;
}
.curator-wrapper .curator-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1.5rem;
}
.curator-wrapper .curator-info h2 {
  font-size: 3rem;
}
.curator-wrapper .create {
  cursor: pointer;
  background: #004AAD;
  color: #fff;
  padding: 0.7rem 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.curator-wrapper .create span {
  font-size: 1.2rem;
}
.curator-wrapper .create span:nth-child(1) {
  width: 50%;
}
.curator-wrapper .create span:nth-child(2) {
  padding: 1rem;
  background: #fff;
  border-radius: 9px;
}
.curator-wrapper .curator-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 3rem;
  flex-wrap: wrap;
}
.curator-wrapper .curator-options .opt-list {
  flex-grow: 1;
  cursor: pointer;
  align-items: center;
  background: #eee;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
}
.curator-wrapper .curator-options .opt-list span:nth-child(2) {
  border: 1px solid #333;
  border-radius: 10px;
  padding: 1rem;
}/*# sourceMappingURL=curator.css.map */